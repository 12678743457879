import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const NavbarMenu = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
      <Navbar.Brand as={Link} to="/">
        Moroccan Dishes
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/">
            Home
          </Nav.Link>

          <NavDropdown title="Dishes" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/salads">
              Salads
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/main-dishes">
              Main Dishes
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/desserts">
              Desserts
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to="/drinks">
              Drinks
            </NavDropdown.Item>
          </NavDropdown>

          <Nav.Link as={Link} to="/contactForm">
            Contact
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarMenu;

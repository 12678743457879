import React from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import image1 from "../image/caro1.png";
import image2 from "../image/caro2.png";
import image3 from "../image/caro3.png";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Home = () => {
  return (
    <AutoplaySlider
      play={true}
      cancelOnInteraction={false} // should stop playing on user interaction
      interval={6000}
      className="awesome-slider"
      bullets={false} // Set to true if you want navigation bullets
    >
      <div data-src={image1} />
      <div data-src={image2} />
      <div data-src={image3} />
    </AutoplaySlider>
  );
};

export default Home;

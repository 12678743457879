import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { moroccanDishesData } from "../Data";
import "./Dishes.css"; // Adjust the import path according to your file structure

const CategoryPage = ({ category }) => {
  const [dishes, setDishes] = useState([]);

  useEffect(() => {
    const filteredDishes = moroccanDishesData.dishes.filter(
      (dish) => dish.category === category
    );
    setDishes(filteredDishes);
  }, [category]);

  return (
    <Container>
      <h2>{category.replace(/^\w/, (c) => c.toUpperCase())}</h2>
      <Row>
        {dishes.map((dish, index) => (
          <Col key={index} md={4} className="mb-3">
            <Card>
              {/* Check if dish.image exists before trying to render <Card.Img> */}
              {dish.image && <Card.Img variant="top" src={dish.image} />}
              <Card.Body>
                <Card.Title> {dish.name}</Card.Title>
                <Card.Text>Dish Description :{dish.description}</Card.Text>
                {/* Render ingredients if they exist */}
                Dish Ingredients :{" "}
                {dish.ingredients && (
                  <ul>
                    {dish.ingredients.map((ingredient, i) => (
                      <li key={i}>{ingredient}</li>
                    ))}
                  </ul>
                )}
                <Card.Text>Preparation time : {dish.preparationTime}</Card.Text>
                <Card.Text>Instructions : {dish.instructions}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CategoryPage;

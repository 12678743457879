import minttea from "./image/mint-tea.png";
import MoroccanOrangeCake from "./image/carrot-cake.png";

import tajin from "./image/tajin.png";
import fishtajin from "./image/fishtajin.png";
import carrotsalad from "./image/carrotsalad.png";
import tomatosalad from "./image/tomatosalad.png";
import lentilsalad from "./image/lentilsalad.png";

export const moroccanDishesData = {
  dishes: [
    // Example for a few dishes due to space constraints
    {
      name: "Moroccan Carrot Salad",
      description:
        "Grated carrots seasoned with a zesty lemon dressing, garnished with fresh parsley.",
      category: "salads",
      image: carrotsalad,
      ingredients: [
        "4 large carrots, peeled and grated",
        "3 tablespoons olive oil",
        "1 clove garlic, minced",
        "Juice of 1 lemon",
        "2 tablespoons chopped fresh parsley",
        "1 teaspoon ground cumin",
        "Salt and pepper to taste",
      ],
      preparationTime: "15 minutes",
      instructions: [
        "In a large bowl, combine the grated carrots, olive oil, minced garlic, lemon juice, chopped parsley, ground cumin, salt, and pepper.",
        "Toss well to combine.",
        "Let the salad sit for at least 10 minutes before serving to allow flavors to meld.",
      ],
    },
    {
      name: "Moroccan Lentil Salad",
      description:
        "A hearty and flavorful salad with lentils, vibrant vegetables, and a cumin-infused dressing.",
      category: "salads",
      image: lentilsalad, // Assuming you have an image file
      ingredients: [
        "1 cup green or brown lentils, cooked and drained",
        "1/2 cup cucumber, diced",
        "1/2 cup red bell pepper, diced",
        "1/4 cup red onion, finely chopped",
        "3 tablespoons olive oil",
        "2 tablespoons lemon juice",
        "2 tablespoons chopped fresh parsley",
        "1 tablespoon chopped fresh mint",
        "1 teaspoon ground cumin",
        "1/2 teaspoon salt",
        "1/4 teaspoon black pepper",
      ],
      preparationTime: "20 minutes",
      instructions: [
        "In a large bowl, combine the lentils, cucumber, bell pepper, onion, olive oil, lemon juice, parsley, mint, cumin, salt, and pepper.",
        "Toss well to combine.",
        "Taste and adjust seasonings as needed.",
        "Serve at room temperature or chilled.",
      ],
    },
    {
      name: "Moroccan Tomato Salad",
      description:
        "A fresh, flavorful salad with diced tomatoes, herbs, and a light vinaigrette.",
      category: "salads",
      image: tomatosalad, // Assuming you have an image file
      ingredients: [
        "4 tomatoes, diced or cherry tomatoes, halved",
        "1/4 cup red onion, minced finely",
        "2tablespoons olive oil",
        "2 tablespoons lemon juice",
        "2 tablespoons chopped fresh cilantro",
        "1/2 teaspoon ground cumin",
        "Salt and pepper to taste",
      ],
      preparationTime: "10 minutes",
      instructions: [
        "In a medium bowl, combine tomatoes, onion, olive oil, lemon juice, cilantro, cumin, salt, and pepper.",
        "Toss gently to coat.",
        "Serve immediately or chill slightly before serving.",
      ],
    },

    {
      name: "Chicken Tagine with Olives and Lemons",
      description:
        "Slow-cooked chicken with preserved lemons and olives, seasoned with Moroccan spices.",
      category: "main-dishes",
      image: tajin,
      ingredients: [
        "1 whole chicken, cut into pieces",
        "2 tablespoons olive oil",
        "1 onion, finely chopped",
        "3 cloves garlic, minced",
        "1 teaspoon ground ginger",
        "1 teaspoon turmeric",
        "1/2 teaspoon ground cinnamon",
        "1 preserved lemon, cut into slices",
        "1 cup green olives",
        "2 cups chicken broth",
        "Salt and pepper to taste",
        "Fresh cilantro for garnish",
      ],
      preparationTime: "2 hours",
      instructions: [
        "In a large tagine or heavy-bottomed pot, heat the olive oil over medium heat.",
        "Add the onion and garlic, cooking until softened.",
        "Season the chicken pieces with salt, pepper, ginger, turmeric, and cinnamon, then add to the pot.",
        "Brown the chicken on all sides.",
        "Add the preserved lemon, olives, and chicken broth, then bring to a simmer.",
        "Cover and cook on low heat for 1.5 hours, until the chicken is tender.",
        "Garnish with fresh cilantro before serving.",
      ],
    },

    {
      name: "Fish Chermoula",
      description:
        "Grilled fish marinated in chermoula, a herby, garlic, and lemon sauce.",
      category: "main-dishes",
      image: fishtajin,
      ingredients: [
        "4 fish fillets",
        "For the Chermoula:",
        "1 bunch cilantro, chopped",
        "1 bunch parsley, chopped",
        "4 cloves garlic, minced",
        "1 teaspoon paprika",
        "1 teaspoon cumin",
        "Juice of 1 lemon",
        "2 tablespoons olive oil",
        "Salt and pepper to taste",
      ],
      preparationTime: "45 minutes",
      instructions: [
        "Prepare the chermoula by mixing cilantro, parsley, garlic, spices, lemon juice, and olive oil.",
        "Marinate the fish fillets in the chermoula for at least 30 minutes.",
        "Grill the fish on medium heat for about 5-7 minutes on each side.",
        "Serve with lemon slices and additional fresh herbs.",
      ],
    },

    {
      name: "Moroccan Orange Cake",
      description:
        "Moist orange cake made with fresh oranges and topped with a citrus glaze.",
      category: "desserts",
      image: MoroccanOrangeCake,
      ingredients: [
        "2 cups all-purpose flour",
        "1 tablespoon baking powder",
        "1/2 teaspoon salt",
        "4 eggs",
        "1 cup sugar",
        "1/2 cup vegetable oil",
        "Zest of 1 orange",
        "1/2 cup orange juice",
        "For the glaze:",
        "1 cup powdered sugar",
        "2 tablespoons orange juice",
      ],
      preparationTime: "1 hour",
      instructions: [
        "Preheat your oven to 350°F (175°C). Grease and flour a 9-inch round cake pan.",
        "In a bowl, combine flour, baking powder, and salt.",
        "In another bowl, beat eggs and sugar until light and fluffy. Gradually add oil, orange zest, and orange juice.",
        "Combine the wet and dry ingredients, stirring until just mixed. Pour into the prepared pan.",
        "Bake for about 35 minutes or until a toothpick inserted into the center comes out clean.",
        "Mix powdered sugar and orange juice to make the glaze. Drizzle over the cooled cake.",
      ],
    },
    {
      name: "Moroccan Orange Cake",
      description:
        "Moist orange cake made with fresh oranges and topped with a citrus glaze.",
      category: "desserts",
      image: MoroccanOrangeCake,
      ingredients: [
        "2 cups all-purpose flour",
        "1 tablespoon baking powder",
        "1/2 teaspoon salt",
        "4 eggs",
        "1 cup sugar",
        "1/2 cup vegetable oil",
        "Zest of 1 orange",
        "1/2 cup orange juice",
        "For the glaze:",
        "1 cup powdered sugar",
        "2 tablespoons orange juice",
      ],
      preparationTime: "1 hour",
      instructions: [
        "Preheat your oven to 350°F (175°C). Grease and flour a 9-inch round cake pan.",
        "In a bowl, combine flour, baking powder, and salt.",
        "In another bowl, beat eggs and sugar until light and fluffy. Gradually add oil, orange zest, and orange juice.",
        "Combine the wet and dry ingredients, stirring until just mixed. Pour into the prepared pan.",
        "Bake for about 35 minutes or until a toothpick inserted into the center comes out clean.",
        "Mix powdered sugar and orange juice to make the glaze. Drizzle over the cooled cake.",
      ],
    },
    {
      name: "Moroccan Orange Cake",
      description:
        "Moist orange cake made with fresh oranges and topped with a citrus glaze.",
      category: "desserts",
      image: MoroccanOrangeCake,
      ingredients: [
        "2 cups all-purpose flour",
        "1 tablespoon baking powder",
        "1/2 teaspoon salt",
        "4 eggs",
        "1 cup sugar",
        "1/2 cup vegetable oil",
        "Zest of 1 orange",
        "1/2 cup orange juice",
        "For the glaze:",
        "1 cup powdered sugar",
        "2 tablespoons orange juice",
      ],
      preparationTime: "1 hour",
      instructions: [
        "Preheat your oven to 350°F (175°C). Grease and flour a 9-inch round cake pan.",
        "In a bowl, combine flour, baking powder, and salt.",
        "In another bowl, beat eggs and sugar until light and fluffy. Gradually add oil, orange zest, and orange juice.",
        "Combine the wet and dry ingredients, stirring until just mixed. Pour into the prepared pan.",
        "Bake for about 35 minutes or until a toothpick inserted into the center comes out clean.",
        "Mix powdered sugar and orange juice to make the glaze. Drizzle over the cooled cake.",
      ],
    },

    {
      name: "Mint Tea",
      description:
        "Traditional Moroccan tea made with green tea, mint leaves, and sugar.",
      category: "drinks",
      ingredients: ["Green Tea", "Mint", "Water", "Sugar"],
      image: minttea,
      preparationTime: "15 minutes",
      instructions:
        "Steep green tea with boiling water, add mint leaves and sugar, and serve hot.",
    },
    {
      name: "Mint Tea",
      description:
        "Traditional Moroccan tea made with green tea, mint leaves, and sugar.",
      category: "drinks",
      ingredients: ["Green Tea", "Mint", "Water", "Sugar"],
      image: minttea,
      preparationTime: "15 minutes",
      instructions:
        "Steep green tea with boiling water, add mint leaves and sugar, and serve hot.",
    },
    {
      name: "Mint Tea",
      description:
        "Traditional Moroccan tea made with green tea, mint leaves, and sugar.",
      category: "drinks",
      ingredients: ["Green Tea", "Mint", "Water", "Sugar"],
      image: minttea,
      preparationTime: "15 minutes",
      instructions:
        "Steep green tea with boiling water, add mint leaves and sugar, and serve hot.",
    },
    // Add additional dishes with ingredients, preparation time, and instructions following the same format
  ],
};

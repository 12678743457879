import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavbarMenu from "./components/NavbarMenu";
import Home from "./components/Home";

import CategoryPage from "./components/CategoryPage";
import ContactForm from "./components/ContactForm";

function App() {
  return (
    <Router>
      <NavbarMenu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/" element={<CategoryPage category="home" />} />
        <Route path="/salads" element={<CategoryPage category="salads" />} />
        <Route
          path="/main-dishes"
          element={<CategoryPage category="main-dishes" />}
        />
        <Route
          path="/desserts"
          element={<CategoryPage category="desserts" />}
        />
        <Route path="/drinks" element={<CategoryPage category="drinks" />} />

        <Route path="/contactForm" element={<ContactForm />} />
      </Routes>
    </Router>
  );
}

export default App;

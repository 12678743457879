import React, { useState } from "react";
import "./ContactForm.css";
import emailjs from "emailjs-com";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const ContactForm = () => {
  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', and 'YOUR_USER_ID' with your actual values
    emailjs
      .sendForm(
        "service_9uac0fe",
        "contact_form",
        e.target,
        "fwssKpsV-eocFu5zZ"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          // Reset form or show a success message
          setFormDetails({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          console.log("Failed to send email:", error.text);
          // Optionally reset form or show an error message
        }
      );
  };

  return (
    <div className="form-wrapper">
      <div className="contact-form-container">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            value={formDetails.name}
            onChange={handleChange}
            placeholder="Your Name"
            required
          />
          <input
            type="email"
            name="email"
            value={formDetails.email}
            onChange={handleChange}
            placeholder="Your Email"
            required
          />
          <textarea
            name="message"
            value={formDetails.message}
            onChange={handleChange}
            placeholder="Your Message"
            required
          ></textarea>
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
